(function () {
    angular.module('informaApp')
        .service('MetricsTypes', MetricsTypes);

    function MetricsTypes() {
        return {
            pos: 0,
            loa: 1,
            duration: 2,
            medianDuration: 3
        }
    }
})();